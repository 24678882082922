import React from 'react'

const Home = () => {
  return (
    <div>
      <h3 className='text-5xl text-center p-4 font-bold bg-red-600 text-white shadow-md m-2'>The website is undergoing scheduled maintenance</h3>
      <p className='text-center text-2xl font-semibold bg-blue-700 m-2 p-4 text-white'>Sorry for the inconvenience. Come back a bit later, we will be ready soon!</p>
    </div>
  )
}

export default Home
